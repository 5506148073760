import React, { Fragment } from 'react';
import spinner from '../img/spinner.gif';

const Spinner = (props) => {
  const { width } = props;
  return (
    <Fragment>
      <img src={spinner} style={{ width: width || '100px', margin: 'auto', display: 'block' }} alt='Loading' />
    </Fragment>
  );
};

export default Spinner;
