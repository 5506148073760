import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { authRoute, localToken } from '../utils/constants';

const initialState = {
  loggedIn: false,
  doneLoading: false,
  user: {},
  signData: undefined,
};

export const Slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user || { error: 'Something Went Wrong' };
      state.signData = action.payload.signData;
      state.loggedIn = true;
      state.doneLoading = true;
    },
  },
});

export const loadUser = (id) => async (dispatch) => {
  try {
    console.log('LoadUser');
    const response = await axios.post(`${authRoute}/auth/getUser`, { localToken, userId: id || 'XXX' });
    dispatch(setUser(response.data));
  } catch (err) {
    return {};
  }
};

export const createUser = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/auth/createUser`, formData);
    dispatch(setUser(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const loginUser = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/auth/loginUser`, formData);
    dispatch(setUser(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem('vectorserver_localToken');
    dispatch(setUser({ user: {} }));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setUser } = Slice.actions;

export default Slice.reducer;
