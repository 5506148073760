import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, loadUser } from '../../store/authSlice';
import { reverseUrl } from '../../utils/helpers';
import Spinner from '../../layout/Spinner';

const Login = () => {
  const dispatch = useDispatch();
  const { redirect } = useParams();

  const { user, signData } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({});
  const [waitAwhile, setWaitAwhile] = useState(false);

  const handleFormData = (value, key) => {
    setFormData((state) => {
      return { ...state, [key]: value };
    });
  };

  const loggedIn = user && user._id;
  useEffect(() => {
    dispatch(loadUser(user?._id));
    setTimeout(() => {
      setWaitAwhile(true);
    }, 3000);
  }, [dispatch, user]);

  if (signData) return <Navigate to={`/loginuser/${signData}`} />;
  if (loggedIn) {
    return <Navigate to={`${reverseUrl(redirect) || '/'}`} />;
  }
  return waitAwhile ? (
    <div className='row d-flex justify-content-center p-4'>
      <div className='col-md-4'>
        <div className='form mb-4'>
          <input
            type='email'
            id='form2Example1'
            className='form-control'
            onChange={(e) => handleFormData(e.target.value, 'email')}
          />
          <label className='form-label'>Email address</label>
        </div>

        <div className='form-outline mb-4'>
          <input
            type='password'
            id='form2Example2'
            className='form-control'
            onChange={(e) => handleFormData(e.target.value, 'password')}
          />
          <label className='form-label'>Password</label>
        </div>

        <button type='button' className='btn btn-primary btn-block mb-4' onClick={() => dispatch(loginUser(formData))}>
          Sign in
        </button>
      </div>
    </div>
  ) : (
    <Spinner width='150' />
  );
};

export default Login;
