import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import scrapeSlice from './scrapeSlice';
import generalSlice from './generalSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    scrape: scrapeSlice,
    general: generalSlice,
  },
});
