import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { localToken, authRoute, vectorServerURI } from '../utils/constants';

const initialState = {};

export const Slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    generalSet: (state, action) => {
      if (action.payload.setLoading) {
        state[action.payload.setLoading] = false;
      }
      state[action.payload.reduxField] = action.payload.value;
    },
  },
});

export const apiAction = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/${formData.route}`, { ...formData, localToken });
    dispatch(generalSet({ reduxField: formData.reduxField, value: response.data }));
  } catch (err) {
    throw new Error(err);
  }
};

export const vectorServer = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${vectorServerURI}/${formData.route}`, { ...formData, localToken });
    dispatch(generalSet({ reduxField: formData.reduxField, value: response.data, setLoading: formData.setLoading }));
  } catch (err) {
    throw new Error(err);
  }
};

export const { generalSet } = Slice.actions;

export default Slice.reducer;
