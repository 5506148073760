import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../store/authSlice';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [pathName, setPathName] = useState(location.pathname);
  useEffect(() => {
    setPathName(location.pathname);
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);

  const titles = [
    { width: 0, to: '/', text: 'Home' },
    // { width: 930, to: '/VectorTables', text: 'Vector Tables' },
    // { width: 1030, to: '/PipiPageScrapes', text: 'PipiAds Pages' },
    // { width: 1290, to: '/AdHeart/AdHeartPages', text: 'AdHeart Ads' },
  ];

  return (
    <div className='d-flex justify-content-end p-3 shadow'>
      {titles.map((title, i) => {
        if (dimensions.width < title.width) return null;
        if (i === 0)
          return (
            <span
              key={`headerTitle${i}`}
              className={`header-box ${title.to === pathName ? 'text-primary' : ''}`}
              style={{ marginLeft: '150px' }}
            >
              <Link to={title.to}>{title.text}</Link>
            </span>
          );
        return (
          <span key={`headerTitle${i}`} className={`header-box ${title.to === pathName ? 'text-primary' : ''} ms-4`}>
            <Link to={title.to}>{title.text}</Link>
          </span>
        );
      })}

      <span className='header-button ms-auto  d-flex justify-content-center'>
        <span
          className='header-button-text mt-auto'
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </span>
        <span className='header-button-circle '></span>
      </span>
    </div>
  );
};

export default Header;
