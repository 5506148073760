import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import PrivateRoute from './layout/PrivateRoute';
import Layout from './layout/Layout';
import App from './App';
import Login from './components/Auth/Login';
import LoginUser from './components/Auth/LoginUser';

import './index.css';
import './App.css';

import Register from './components/Auth/Register';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/login/:redirect',
        element: <Login />,
      },
      {
        path: '/loginuser/:token',
        element: <LoginUser />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/',
        element: <App />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
