import { useState } from 'react';
import { MessageClient } from 'cloudmailin';
import axios from 'axios';
import './App.css';
import { Navigate } from 'react-router-dom';

const mailUser = process.env.REACT_APP_MAIL_USER;
const mailToken = process.env.REACT_APP_MAIL_TOKEN;

function App() {
  const checkFormData = (formData) => {
    const { name, email, content } = formData;
    if (!name || !email || !content) {
      return false;
    }
    return true;
  };

  const [formData, setFormData] = useState({});
  const [requestSent, setRequestSent] = useState(false);

  const handleChange = (field, value) => {
    setFormData((state) => {
      return { ...state, [field]: value };
    });
  };

  const sendMail = async (formData) => {
    try {
      const { name, email, phone, content } = formData;

      const client = new MessageClient({ username: mailUser, apiKey: mailToken });
      const response = await client.sendMessage({
        to: 'Socialartschool@gmail.com',
        from: 'admin@pandoraschool.com',
        plain: `${name} - ${email} - ${phone} - ${content}`,
        html: `<p>שם - ${name}</p><br>
               <p>מייל - ${email}</p><br>
               <p>טלפון - ${phone}</p><br>
               <p>${content}</p>
               `,
        subject: 'הודעה חדשה מפנדורה',
      });
      return response;
    } catch (error) {
      console.log(error);
      return { error: 'Server Error' };
    }
  };
  return (
    <div className='container'>
      <div className='d-flex justify-content-center mt-4 slide-in-left'>
        <h1>
          <b>פנדורה</b>
        </h1>
      </div>
      <div className='d-flex justify-content-center slide-in-right border-bottom'>
        <h6 className='text-secondary'>בית הספר לאומנות חברתית</h6>
      </div>
      <section className='mb-4 mt-5'>
        <div className='row d-flex justify-content-center slide-in-left'>
          {requestSent ? (
            <div className='col-md-8 mb-md-0 mb-5'>
              <div className='d-flex justify-content-center mt-4 slide-in-left mt-5'>
                <h1>
                  <b>תודה שפניתם</b>
                </h1>
              </div>
              <div className='d-flex justify-content-center mt-4 slide-in-left'>
                <h1>
                  <b>אנו נחזור אליכם בהקדם... אם זה האפשרי</b>
                </h1>
              </div>
            </div>
          ) : (
            <div className='col-md-8 mb-md-0 mb-5'>
              <form id='contact-form' name='contact-form' action='mail.php' method='POST'>
                <div className='row mt-4'>
                  <div className='col-md-4'>
                    <div className='md-form mb-0'>
                      <label className=''>שם מלא </label>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        onChange={(e) => handleChange('name', e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='md-form mb-0'>
                      <label className=''>אימייל</label>
                      <input
                        type='text'
                        id='email'
                        name='email'
                        className='form-control'
                        onChange={(e) => handleChange('email', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='md-form mb-0'>
                      <label className=''>מספר טלפון</label>
                      <input
                        type='text'
                        id='phone'
                        name='phone'
                        className='form-control'
                        onChange={(e) => handleChange('phone', e.target.value)}
                      />{' '}
                    </div>
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='col-md-12'>
                    <div className='md-form'>
                      <label>דברו אלינו</label>
                      <textarea
                        type='text'
                        id='content'
                        name='content'
                        rows='6'
                        className='form-control md-textarea'
                        onChange={(e) => handleChange('content', e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>

              {checkFormData(formData) ? (
                <div className='text-center text-md-left mt-4'>
                  <button
                    className='btn btn-lg btn-outline-light'
                    onClick={async () => {
                      const result = await sendMail(formData);
                      if (result.id) {
                        setRequestSent(result.id);
                      }
                    }}
                  >
                    שליחה
                  </button>
                </div>
              ) : null}
            </div>
          )}
          <div className='col-md-3 text-center'>
            <ul className='list-unstyled mb-0'>
              <li>
                <a href='https://wa.me/9720586666244'>
                  <i className='fas fa-phone mt-5 fa-2x'></i>
                  <p>58-666-6244 972+</p>
                </a>
              </li>

              <li>
                <i className='fas fa-envelope mt-4 fa-2x'></i>
                <p>SocialArtSchool@gmail.com</p>
              </li>
              <li>
                <a href='https://www.instagram.com/socialartschool/'>
                  <i className='fa-brands fa-instagram fa-2x mt-4'></i>
                  <p>Instagram</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
