import url from 'url';
import { read, utils } from 'xlsx';

export const parseJson = (str) => {
  try {
    const parsed = JSON.parse(str);
    return parsed;
  } catch (error) {
    console.log(error);
    return str;
  }
};
export const setAuthToken = (token) => {
  localStorage.setItem('vectorserver_localToken', token);
};

export const reverseUrl = (url) => {
  try {
    if (!url) {
      return '/';
    }
    const urlArray = url.split('SDFWE_ORMTLDR');
    return urlArray.join('/');
  } catch (error) {
    return '/';
  }
};

export const parseUrl = (url) => {
  try {
    if (!url) {
      return '/';
    }
    const urlArray = url.split('/');
    return urlArray.join('SDFWE_ORMTLDR');
  } catch (error) {
    return '/';
  }
};

export const transformPipiAdsToXlsx = (ppAds) => {
  try {
    const body = [];
    ppAds.ads.map((ad) => {
      const entries = Object.entries(ad);
      const row = {};
      entries.map((entry) => {
        row[entry[0]] = entry[1];
        return null;
      });
      body.push(row);
      return null;
    });
    return body;
  } catch (error) {
    return [];
  }
};

export const getKWKeyWords = (fullUrl) => {
  if (!fullUrl || !fullUrl.includes('http')) {
    return [];
  }
  try {
    const url_parts = url.parse(fullUrl, true);
    const query = url_parts.query;
    const entries = Object.entries(query);
    const filteredEntries = entries.filter(
      (entry) => entry[0].includes('kw') || entry[0].includes('KW') || entry[0].includes('adtitle')
    );
    const result = {};

    filteredEntries.map((entry) => {
      result[entry[0]] = entry[1];
      return null;
    });

    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const readUploadFile = (e) => {
  return new Promise((resolve, reject) => {
    e.preventDefault();
    try {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = read(data, { type: 'array' });
          const sheets = [];
          workbook.SheetNames.map((sn) => {
            const sheetName = sn;
            const worksheet = workbook.Sheets[sheetName];
            const json = utils.sheet_to_json(worksheet);
            const entries = Object.entries(json[0]);
            const headers = entries.map((entry) => entry[0]);
            const body = json.map((js) => {
              const jsEntries = Object.entries(js);
              const values = jsEntries.map((jsEn) => jsEn[1]);
              return values;
            });
            sheets.push({ sheetName, headers, body, json });
            return null;
          });
          resolve(sheets);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } catch (error) {
      reject(error);
    }
  });
};
