import { countries } from './regions';
export const localToken = localStorage.getItem('vectorserver_localToken');
export const authRoute = process.env.REACT_APP_AUTH_ROUTE;
export const backURI = process.env.REACT_APP_BACK_URI;
export const vectorServerURI = process.env.REACT_APP_VECTOR_SERVER_URI;

export const adHeartSearchParams = () => {
  return [
    {
      id: 'HeartSearchParams' + 1,
      type: 'input',
      title: 'Through text',
      param: 'in_text',
      placeholder: 'Word or phrase to search through creative text',
    },
    {
      id: 'HeartSearchParams' + 2,
      type: 'select',
      title: 'In Text Type',
      param: 'in_text_type',
      options: [
        { title: 'Exact prase', value: null },
        { title: 'Through whole text', value: 'all' },
      ],
    },
    {
      id: 'HeartSearchParams' + 3,
      type: 'input',
      title: 'In link',
      param: 'in_links',
      placeholder: 'Word/domain in creatives link',
    },
    {
      id: 'HeartSearchParams' + 4,
      type: 'multiSelect',
      title: 'Choose campaign GEO',
      param: 'geos%5B%5D',
      options: countries,
    },
    { id: 'HeartSearchParams' + 5, type: 'numnber', title: 'Geos, to', param: 'geos_cnt', placeholder: 5 },
    { id: 'HeartSearchParams' + 6, type: 'input', title: 'In domain zone', param: 'domain_zone', placeholder: '.com' },
    {
      id: 'HeartSearchParams' + 7,
      type: 'input',
      title: 'FB-page (id)',
      param: 'fb_page_id',
      placeholder: 'FB page id or link',
    },
    {
      id: 'HeartSearchParams' + 8,
      type: 'input',
      title: 'Search for creatives title (of the page)',
      param: 'fb_page_title',
      placeholder: 'Word or phrase to search in creatives title',
    },
    {
      id: 'HeartSearchParams' + 9,
      type: 'input',
      title: 'Application (link or id)',
      param: 'app_id',
      placeholder: 'link or id...',
    },
    {
      id: 'HeartSearchParams' + 10,
      type: 'select',
      title: 'Application platform',
      param: 'app_platform%5B%5D',
      options: [
        { title: 'Android', value: 'android' },
        { title: 'iOS', value: 'ios' },
      ],
    },
    { id: 'HeartSearchParams' + 11, type: 'input', title: 'On IP', param: 'ip', placeholder: 'Domain or IP' },
    {
      id: 'HeartSearchParams' + 12,
      type: 'select',
      title: 'Text availability',
      param: 'has_text',
      options: [
        { title: 'With text', value: 'with' },
        { title: 'Without text', value: 'without' },
      ],
    },
    {
      id: 'HeartSearchParams' + 14,
      type: 'multiSelect',
      title: 'Publishing platform',
      param: 'platforms%5B%5D',
      options: [
        { title: 'Facebook', value: 'facebook' },
        { title: 'Instagram', value: 'instagram' },
        { title: 'Audience network', value: 'audience_network' },
      ],
    },
    {
      id: 'HeartSearchParams' + 15,
      type: 'select',
      title: 'Media type',
      param: 'content_type',
      options: [
        { title: 'Image', value: 'image' },
        { title: 'Video', value: 'video' },
      ],
    },
    {
      id: 'HeartSearchParams' + 16,
      type: 'multiSelect',
      title: 'Format',
      param: 'display_formats%5B%5D',
      options: [
        { title: 'Image', value: 'image' },
        { title: 'Video', value: 'video' },
        { title: 'Multi Images', value: 'multi_images' },
        { title: 'Carousel', value: 'carousel' },
        { title: 'Event', value: 'event' },
        { title: 'DCO', value: 'dco' },
        { title: 'DPA', value: 'dpa' },
        { title: 'Text', value: 'text' },
        { title: 'Page Like', value: 'page_like' },
        { title: 'Multi Videos', value: 'multi_videos' },
        { title: 'Multi Medias', value: 'multi_medias' },
      ],
    },
    {
      id: 'HeartSearchParams' + 17,
      type: 'multiSelect',
      title: 'Button type',
      param: 'cta_types%5B%5D',
      options: [
        { title: 'LEARN_MORE', value: 'LEARN_MORE' },
        { title: 'SHOP_NOW', value: 'SHOP_NOW' },
        { title: 'MESSAGE_PAGE', value: 'MESSAGE_PAGE' },
        { title: 'VIEW_INSTAGRAM_PROFILE', value: 'VIEW_INSTAGRAM_PROFILE' },
        { title: 'SIGN_UP', value: 'SIGN_UP' },
        { title: 'LIKE_PAGE', value: 'LIKE_PAGE' },
        { title: 'INSTALL_MOBILE_APP', value: 'INSTALL_MOBILE_APP' },
        { title: 'BOOK_TRAVEL', value: 'BOOK_TRAVEL' },
        { title: 'EVENT_RSVP', value: 'EVENT_RSVP' },
        { title: 'APPLY_NOW', value: 'APPLY_NOW' },
        { title: 'WHATSAPP_MESSAGE', value: 'WHATSAPP_MESSAGE' },
        { title: 'GET_OFFER', value: 'GET_OFFER' },
        { title: 'GET_DIRECTIONS', value: 'GET_DIRECTIONS' },
        { title: 'WATCH_MORE', value: 'WATCH_MORE' },
        { title: 'GET_QUOTE', value: 'GET_QUOTE' },
        { title: 'DOWNLOAD', value: 'DOWNLOAD' },
        { title: 'CONTACT_US', value: 'CONTACT_US' },
        { title: 'PLAY_GAME', value: 'PLAY_GAME' },
        { title: 'NO_BUTTON', value: 'NO_BUTTON' },
        { title: 'INSTAGRAM_MESSAGE', value: 'INSTAGRAM_MESSAGE' },
        { title: 'BUY_TICKETS', value: 'BUY_TICKETS' },
        { title: 'CALL_NOW', value: 'CALL_NOW' },
        { title: 'SUBSCRIBE', value: 'SUBSCRIBE' },
        { title: 'DONATE_NOW', value: 'DONATE_NOW' },
        { title: 'LISTEN_NOW', value: 'LISTEN_NOW' },
        { title: 'SEE_MENU', value: 'SEE_MENU' },
        { title: 'GET_OFFER_VIEW', value: 'GET_OFFER_VIEW' },
        { title: 'USE_MOBILE_APP', value: 'USE_MOBILE_APP' },
        { title: 'REQUEST_TIME', value: 'REQUEST_TIME' },
        { title: 'USE_APP', value: 'USE_APP' },
        { title: 'GET_SHOWTIMES', value: 'GET_SHOWTIMES' },
        { title: 'BUY_NOW', value: 'BUY_NOW' },
        { title: 'INSTALL_APP', value: 'INSTALL_APP' },
        { title: 'ORDER_NOW', value: 'ORDER_NOW' },
        { title: 'OPEN_LINK', value: 'OPEN_LINK' },
        { title: 'SAVE', value: 'SAVE' },
        { title: 'SELL_NOW', value: 'SELL_NOW' },
        { title: 'LISTEN_MUSIC', value: 'LISTEN_MUSIC' },
        { title: 'DONATE', value: 'DONATE' },
        { title: 'WATCH_VIDEO', value: 'WATCH_VIDEO' },
        { title: 'FIND_YOUR_GROUPS', value: 'FIND_YOUR_GROUPS' },
        { title: 'REGISTER_NOW', value: 'REGISTER_NOW' },
        { title: 'BOOK_TEST_DRIVE', value: 'BOOK_TEST_DRIVE' },
        { title: 'CHECK_AVAILABILITY', value: 'CHECK_AVAILABILITY' },
        { title: 'ADD_TO_CART', value: 'ADD_TO_CART' },
        { title: 'INTERESTED', value: 'INTERESTED' },
        { title: 'BET_NOW', value: 'BET_NOW' },
        { title: 'TRY_IT', value: 'TRY_IT' },
        { title: 'VOTE_NOW', value: 'VOTE_NOW' },
        { title: 'SEE_MORE', value: 'SEE_MORE' },
        { title: 'CALL', value: 'CALL' },
        { title: 'CONTACT', value: 'CONTACT' },
        { title: 'START_ORDER', value: 'START_ORDER' },
        { title: 'GET_EVENT_TICKETS', value: 'GET_EVENT_TICKETS' },
        { title: 'GET_MOBILE_APP', value: 'GET_MOBILE_APP' },
      ],
    },
    {
      id: 'HeartSearchParams' + 18,
      type: 'select',
      title: 'Lead-form',
      param: 'has_land_form',
      options: [
        { title: 'Only without lead-form', value: 'without' },
        { title: 'Only with led-form', value: 'with' },
      ],
    },
    {
      id: 'HeartSearchParams' + 19,
      type: 'datePicker',
      title: 'Date of teasers (from)',
      param: 'date_created_from',
    },
    { id: 'HeartSearchParams' + 20, type: 'datePicker', title: 'Date of teasers (to)', param: 'date_created_to' },
    {
      id: 'HeartSearchParams' + 21,
      type: 'number',
      title: 'Active days (from)',
      param: 'active_days_from',
      placeholder: 5,
    },
    {
      id: 'HeartSearchParams' + 22,
      type: 'number',
      title: 'Active days (to)',
      param: 'active_days_to',
      placeholder: 30,
    },
    {
      id: 'HeartSearchParams' + 23,
      type: 'select',
      title: 'Use personal blacklist filter',
      param: 'use_blacklist',
      options: [
        { title: 'No', value: 'false' },
        { title: 'Yes', value: 'true' },
      ],
    },
    {
      id: 'HeartSearchParams' + 24,
      type: 'select',
      title: 'Sorting',
      param: 'sort_type',
      options: [
        { title: 'Campaign date', value: null },
        { title: 'Campaign duration', value: 'days_active' },
      ],
    },
  ];
};
