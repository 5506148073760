import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import BurgirMenu from './BurgirMenu';

const Layout = () => {
  return (
    <>
      {/* <Header /> */}
      {/* <BurgirMenu /> */}
      <Outlet />
    </>
  );
};

export default Layout;
