import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUser } from '../../store/authSlice';
import './register.css';
import { Link, Navigate } from 'react-router-dom';
const inputClassName =
  'block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer';
const labelClassName =
  'peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6';

const Register = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [navigateToLogin, setNavigateToLogin] = useState(false);
  const handleFormData = (field, value) => {
    setFormData((state) => {
      return { ...state, [field]: value };
    });
  };
  if (navigateToLogin) {
    return <Navigate to='/login/SDFWE_ORMTLDR' />;
  }
  return (
    <>
      <div className='d-flex justify-content-center mt-4'>
        <form>
          <div className='relative z-0 w-full mb-6 group'>
            <input
              type='text'
              name='floating_first_name'
              id='floating_first_name'
              className={inputClassName}
              placeholder=' '
              required
              defaultValue={formData.name}
              onChange={(e) => handleFormData('name', e.target.value)}
            />
            <label htmlFor='floating_first_name' className={labelClassName}>
              Name
            </label>
          </div>
          <div className='relative z-0 w-full mb-6 group'>
            <input
              type='email'
              name='floating_email'
              id='floating_email'
              className={inputClassName}
              placeholder=' '
              required
              defaultValue={formData.email}
              onChange={(e) => handleFormData('email', e.target.value)}
            />
            <label htmlFor='floating_email' className={labelClassName}>
              Email address
            </label>
          </div>
          <div className='relative z-0 w-full mb-6 group'>
            <input
              type='password'
              name='floating_password'
              id='floating_password'
              className={inputClassName}
              placeholder=' '
              required
              defaultValue={formData.password}
              onChange={(e) => handleFormData('password', e.target.value)}
            />
            <label htmlFor='floating_password' className={labelClassName}>
              Password
            </label>
          </div>
          <div className='relative z-0 w-full mb-6 group'>
            <input
              type='password'
              name='repeat_password'
              id='floating_repeat_password'
              className={inputClassName}
              placeholder=' '
              required
              defaultValue={formData.password2}
              onChange={(e) => handleFormData('password2', e.target.value)}
            />
            <label htmlFor='floating_repeat_password' className={labelClassName}>
              Confirm password
            </label>
          </div>

          <div className='relative z-0 w-full mb-6 group'>
            <input
              type='text'
              name='floating_first_name'
              id='floating_first_name'
              className={inputClassName}
              placeholder=' '
              required
              defaultValue={formData.openAiToken}
              onChange={(e) => handleFormData('openAiToken', e.target.value)}
            />
            <label htmlFor='floating_first_name' className={labelClassName}>
              OpenAi Token
            </label>
          </div>
          <button
            type='submit'
            className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            onClick={() => {
              dispatch(createUser(formData));
            }}
          >
            Submit
          </button>
        </form>
      </div>

      <div className='container register'>
        <div className='row'>
          <div className='col-md-3 register-left'>
            <img src='https://image.ibb.co/n7oTvU/logo_white.png' alt='' />
            <h3>Welcome</h3>
            <p>You are 5 minutes away from having your own vector database</p>
            <input type='submit' name='' value='Login' onClick={() => setNavigateToLogin(true)} />
            <br />
          </div>
          <div className='col-md-9 register-right'>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
                <h3 className='register-heading'>Sign Up</h3>
                <div className='row register-form'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Name *'
                        value=''
                        defaultValue={formData.name}
                        onChange={(e) => handleFormData('name', e.target.value)}
                      />
                    </div>

                    <div className='form-group'>
                      <input type='email' className='form-control' placeholder='Email *' value='' />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input type='password' className='form-control' placeholder='Password *' value='' />
                    </div>
                    <div className='form-group'>
                      <input type='password' className='form-control' placeholder='Confirm Password *' value='' />
                    </div>

                    <button
                      type='submit'
                      className='btn btn-primary me-auto mt-2'
                      onClick={() => {
                        dispatch(createUser(formData));
                      }}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
