import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../layout/Spinner';
import { setAuthToken } from '../../utils/helpers';

const LoginUser = () => {
  const { token } = useParams();
  if (token) {
    setAuthToken(token);
    window.location.href = '/';
  }

  return (
    <div className='d-flex justify-content-center'>
      <Spinner />
    </div>
  );
};

export default LoginUser;
