import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { localToken, backURI, authRoute } from '../utils/constants';

const initialState = {
  pipiScrapes: undefined,
  pipiScrape: undefined,
  pipiScrapeDeleted: undefined,
  scrapeMainData: undefined,
  scrapeMainDataLoading: undefined,
  scrapePageData: undefined,
  scrapePageDataLoading: false,
  pipiPageScrapes: undefined,
  pipiPageScrape: undefined,
  pipiPageScrapeDeleted: undefined,
  // AdHeart.me
  adHeartPages: undefined,
  adHeartPage: undefined,
  adHeartScrapePageData: undefined,
  adHeartScrapePageDataLoading: false,
  adHeartScrapePageDeleted: undefined,
};

export const Slice = createSlice({
  name: 'scrape',
  initialState,
  reducers: {
    setPipiScrapes: (state, action) => {
      state.pipiScrapes = action.payload;
    },
    setPipiScrape: (state, action) => {
      state.pipiScrape = action.payload;
    },
    setPipiScrapeDeleted: (state, action) => {
      state.pipiScrapeDeleted = action.payload;
    },
    setScrapeMainData: (state, action) => {
      state.scrapeMainData = action.payload;
      state.scrapeMainDataLoading = false;
    },
    setScrapeMainDataLoading: (state, action) => {
      state.scrapeMainDataLoading = action.payload;
    },
    setScrapePageDataLoading: (state, action) => {
      state.scrapePageDataLoading = action.payload;
    },
    setScrapePageData: (state, action) => {
      state.scrapePageData = action.payload;
      state.scrapePageDataLoading = false;
    },
    setPipiScrapePageDeleted: (state, action) => {
      state.pipiPageScrapeDeleted = action.payload;
    },
    setPipiPageScrapes: (state, action) => {
      state.pipiPageScrapes = action.payload;
    },
    setPipiPageScrape: (state, action) => {
      state.pipiPageScrape = action.payload;
    },
    // AdHeart.me
    setAdHeartPages: (state, action) => {
      state.adHeartPages = action.payload;
    },
    setAdHeartPage: (state, action) => {
      state.adHeartPage = action.payload;
    },
    setAdHeartScrapePageData: (state, action) => {
      state.adHeartScrapePageData = action.payload;
      state.adHeartScrapePageDataLoading = false;
    },
    setAdHeartScrapePageDataLoading: (state, action) => {
      state.adHeartScrapePageDataLoading = action.payload;
    },
    setAdHeartScrapeDeleted: (state, action) => {
      state.adHeartScrapePageDeleted = action.payload;
    },
    setAddAdsToAdHeartPage: (state, action) => {
      state.adHeartPage = { ...state.adHeartPage, ads: [...state.adHeartPage.ads, ...action.payload] };
    },
  },
});

export const scrapeMain = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${backURI}/pipiScraper`, { ...formData, localToken });
    dispatch(setScrapeMainData(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const deletePipiScrape = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/deletePipiScrape`, { ...formData, localToken });
    dispatch(setPipiScrapeDeleted(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getPipiScrapes = () => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/getPipiScrapes`, { localToken });
    dispatch(setPipiScrapes(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getPipiScrape = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/getPipiScrape`, { id, localToken });
    dispatch(setPipiScrape(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Pages
export const scrapePage = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${backURI}/pipiPageScraper`, { ...formData, localToken });
    dispatch(setScrapePageData(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getPipiPageScrapes = () => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/getPipiPageScrapes`, { localToken });
    dispatch(setPipiPageScrapes(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const deletePipiPageScrape = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/deletePipiPageScrape`, { ...formData, localToken });
    dispatch(setPipiScrapePageDeleted(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getPipiPageScrape = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/getPipiPageScrape`, { id, localToken });
    dispatch(setPipiPageScrape(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// AdHeart.me

export const adHeartPageScraper = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${backURI}/adHeartPageScraper`, { ...formData, localToken });
    dispatch(setAdHeartScrapePageData(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteToAdHeartPageScrape = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/deleteToAdHeartPageScrape`, { ...formData, localToken });
    dispatch(setAdHeartScrapeDeleted(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getAdHeartPages = () => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/getAdHeartPages`, { localToken });
    dispatch(setAdHeartPages(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getAdHeartPage = (id) => async (dispatch) => {
  try {
    const response = await axios.post(`${authRoute}/getAdHeartPage`, { localToken, id });
    if (response?.data?.nextPages) {
      for (let i = 0; i < response.data.nextPages.length; i++) {
        const savedPage = await axios.post(`${authRoute}/getTempPage`, { localToken, id: response.data.nextPages[i] });
        response.data.ads = [...response.data.ads, ...savedPage.data];
      }
      dispatch(setAdHeartPage(response.data));

      return null;
    }
    dispatch(setAdHeartPage(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const {
  setPipiScrapes,
  setScrapeMainData,
  setPipiScrape,
  setPipiScrapeDeleted,
  setScrapePageData,
  setScrapePageDataLoading,
  setScrapeMainDataLoading,
  setPipiPageScrapes,
  setPipiPageScrape,
  setPipiScrapePageDeleted,
  // AdHeart.me
  setAdHeartPages,
  setAdHeartPage,
  setAdHeartScrapePageData,
  setAdHeartScrapePageDataLoading,
  setAdHeartScrapeDeleted,
  setAddAdsToAdHeartPage,
} = Slice.actions;

export default Slice.reducer;
